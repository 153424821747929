import { Router } from '@angular/router';
import { BulkPaymentMasterStatus, FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  SystemRole,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { delay, first, map, take } from 'rxjs';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import {
  RemoveSelectedCSVFileFromBulkPaymentsFormState,
  UpdateFormDataActionWithId,
} from '../../../actions/form-submission.action';
import {
  ApproveBulkPayment,
  BulkPaymentDeclined,
  BulkPaymentSuccess,
  CreateBulkPayment,
} from '../../../actions/payment.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { BulkPaymentService } from '../../../services/bulk-payment-service/bulk-payment-service.service';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { KeycloakWrapperService } from '../../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { AccountState } from '../../../state/account.state';
import { BulkPaymentListState } from '../../../state/bulk-payment.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { ProfileState } from '../../../state/user-profile.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';
export const delayTime = 3000;
export function downloadCsvTemplate(alertHandlerService: AlertHandlerService) {
  const link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href = '/assets/template/bulk-payment-beneficiary-template.csv';
  link.download = 'bulk-payment-beneficiary-template.csv';
  document.body.appendChild(link);
  link.click();
  link.remove();
  alertHandlerService.showAlertFn('success', 'Template Download Successfully');
}

export function proceedCSVFile(
  store: Store,
  accountService: AccountService,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  if (!formActionData?.formData?.myFiles?.[0]?.id) {
    alertHandlerService.showAlertFn('error', 'Not able to proceed without upload file!', 'File Not Added!');
    return;
  }

  store.dispatch(new ClearBulkPaymentList());

  router.navigateByUrl(`/zones/payments/bulk-payment-upload-processing`, {
    replaceUrl: true,
  });
  const payload: uploadFileRequest = {
    fileId: formActionData?.formData.myFiles[0].id,
    fileName: formActionData?.formData.myFiles[0].filename,
    accountId: formActionData?.formData.selectedAccountId,
    inActiveBeneficiaryCheck: true,
    showInBeneficiaryList: false,
  };
  accountService
    .proceedUploadFile(payload)
    .pipe(delay(delayTime))
    .subscribe({
      next: (payload) => {
        store.dispatch(new RemoveSelectedCSVFileFromBulkPaymentsFormState());
        if (payload) {
          store.dispatch(
            new UpdateFormDataActionWithId(
              {
                masterFileId: payload.id,
              },
              FormActionTypeEnum.BULK_PAYMENTS,
            ),
          );
        }
        router.navigateByUrl(`/zones/payments/bulk-payment-beneficiary-review`, {
          replaceUrl: true,
        });
      },
      error: (err) => {
        router.navigateByUrl(`/zones/payments/bulk-payment-upload-download`, {
          replaceUrl: true,
        });
      },
    });
}

export type uploadFileRequest = {
  fileId: string;
  fileName: string;
  accountId: string;
  inActiveBeneficiaryCheck: boolean;
  showInBeneficiaryList: boolean;
};

export type UploadFileResponse = {
  id: string;
};

export function refreshBulkPayment(store: Store) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  store.dispatch(new GetBulkPaymentList(1, 10, formActionData?.formData.masterFileId));
}

export function goToPrePayment(router: Router) {
  router.navigateByUrl(`/zones/payments/bulk-payment-pre-payment-summary`, {
    replaceUrl: true,
  });
}

export function addBeneficiary(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  accountService: AccountService,
) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const formActionCustomData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.CUSTOM_QUESTION),
  );
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  router.navigateByUrl(`/zones/payments/bulk-payment-processing-add-beneficiary`, {
    replaceUrl: true,
  });
  const beneficiaryAddInList = !!formActionCustomData?.formData?.['beneficiary-show-in-beneficiary-list'];
  accountService
    .addBulkPaymentBeneficiary(formActionData?.formData.masterFileId, beneficiaryAddInList)
    .pipe(delay(delayTime))
    .subscribe({
      next: (payload) => {
        if (payload)
          router.navigateByUrl(`/zones/payments/bulk-payment-review-error-handling`, {
            replaceUrl: true,
          });
      },
      error: (err) => {
        alertHandlerService.showAlertFn('error', 'Something went wrong');
        router.navigateByUrl(`/zones/payments/bulk-payment-beneficiary-review`, {
          replaceUrl: true,
        });
      },
    });
}

export function proceedBulkPayment(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  _actions
    .pipe(
      ofActionDispatched(BulkPaymentSuccess),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-summary-elaborated-view`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(BulkPaymentDeclined),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-pre-payment-summary`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();
  redirectAsPerSmsAuthEnabled(widgetProperties, store, router, alertHandlerService);
}

export function redirectAsPerSmsAuthEnabled(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties);
  } else {
    widgetProperties['urlToNavigate'] = 'bulk-payment-processing';
    submitBulkPaymentAPI(store, router, alertHandlerService, isSmsAuthEnabled);
    redirectToPage(router, widgetProperties);
  }
}

export function approveBulkPayment(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  _actions: Actions,
) {
  _actions
    .pipe(
      ofActionDispatched(BulkPaymentSuccess),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-summary-elaborated-view-approver-reject`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(BulkPaymentDeclined),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-pre-payment-summary`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();

  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties);
  } else {
    widgetProperties['urlToNavigate'] = 'bulk-payment-processing';
    submitApproveBulkPaymentAPI(store, router, alertHandlerService, isSmsAuthEnabled);
    redirectToPage(router, widgetProperties);
  }
}
export function submitApproveBulkPaymentAPI(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  isSmsAuthEnabled = true,
) {
  const masterId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  )?.formData.masterFileId as string;
  if (masterId) {
    store.dispatch(new ApproveBulkPayment(masterId));
    if (isSmsAuthEnabled) {
      router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-processing`);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again');
    console.error('payment request data not available, please try again');
  }
}
export function submitBulkPaymentAPI(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  isSmsAuthEnabled = true,
) {
  const masterId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  )?.formData.masterFileId as string;
  if (masterId) {
    store.dispatch(new CreateBulkPayment(masterId));
    if (isSmsAuthEnabled) {
      router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-processing`);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again');
    console.error('payment request data not available, please try again');
  }
}

export function cancelAllPayment(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  accountService: AccountService,
) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  router.navigateByUrl(`/zones/payments/bulk-payment-summary-elaborated-view`, {
    replaceUrl: true,
  });
  accountService
    .cancelAllPayment(formActionData?.formData.masterFileId)
    .pipe(delay(delayTime))
    .subscribe({
      next: (payload) => {
        alertHandlerService.showAlertFn('success', 'Payment cancel successfully');
        if (payload) refreshBulkPayment(store);
      },
      error: (err) => {
        alertHandlerService.showAlertFn('error', 'Something went wrong');
        router.navigateByUrl(`/zones/payments/bulk-payment-summary-elaborated-view`, {
          replaceUrl: true,
        });
      },
    });
}
export function rejectAllPayment(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  bulkPaymentService: BulkPaymentService,
) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  router.navigateByUrl(`/zones/payments/bulk-payment-summary-elaborated-view-approver-reject`, {
    replaceUrl: true,
  });
  bulkPaymentService
    .approveMakeBulkPayment(formActionData?.formData.masterFileId, true)
    .pipe(delay(delayTime))
    .subscribe({
      next: (payload) => {
        alertHandlerService.showAlertFn('success', 'Payment reject successfully');
        if (payload) refreshBulkPayment(store);
      },
      error: (err) => {
        alertHandlerService.showAlertFn('error', 'Something went wrong');
        router.navigateByUrl(`/zones/payments/bulk-payment-summary-elaborated-view-approver-reject`, {
          replaceUrl: true,
        });
      },
    });
}

export function requestPayment(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  bulkPaymentService: BulkPaymentService,
  widgetProperties: BaseWidgetProperties,
) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  widgetProperties['urlToNavigate'] = 'bulk-payment-processing';
  redirectToPage(router, widgetProperties);

  bulkPaymentService
    .requestBulkPayment(formActionData?.formData.masterFileId)
    .pipe(delay(delayTime))
    .subscribe({
      next: (payload) => {
        alertHandlerService.showAlertFn('success', 'Your bulk payment request has been sent for approval');
        router.navigateByUrl(`/zones/payments/bulk-payment-history`, {
          replaceUrl: true,
        });
      },
      error: (err) => {
        alertHandlerService.showAlertFn('error', 'Something went wrong');
      },
    });
}

export function makePayment(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  metadataService: MetadataService,
  graphqlService: GraphqlServiceService,
  attri: UiZoneWidgetAttributeConfig,
  accountService: AccountService,
  formSubmissionService: FormSubmissionService,
  configService: ConfigService,
  feeManagementService: FeeManagementService,
  keycloakService: KeycloakWrapperService,
  ctaButtonSignalService: CtaButtonSignalService,
  bulkPaymentService: BulkPaymentService,
) {
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  //get system config from form state
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);
  if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
    const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
    );
    const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
    );
    const isPaymentApprover = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
    );
    if (isPaymentInitiator) {
      proceedBulkPayment(widgetProperties, store, actions, router, alertHandlerService);
    } else if (isPaymentRequestor || isPaymentApprover) {
      // call request API
      requestPayment(store, router, alertHandlerService, bulkPaymentService, widgetProperties);
    } else {
      alertHandlerService.showAlertFn(
        'error',
        "You don't have permission to make a payment or to request for a payment",
      );
    }
  } else {
    proceedBulkPayment(widgetProperties, store, actions, router, alertHandlerService);
  }
}

export function bulkPaymentApproverRejectButton(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
  configService: ConfigService,
) {
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);

  const data = store.select(BulkPaymentListState.getBulkPaymentList);
  return data.subscribe({
    next: (res) => {
      const masterBulkPaymentStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
      let isDisableButton = false;

      if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
        const isPaymentApprover = !!activeOrg?.roles.find((value) =>
          systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
        );
        if (masterBulkPaymentStatus == BulkPaymentMasterStatus.PENDING_APPROVAL && isPaymentApprover) {
          isDisableButton = true;
        }
        const key = attri?.['buttonActionType'] as string;
        ctaButtonSignalService.setSignal({
          [key]: isDisableButton,
        });
      }
    },
  });
}

export function bulkPaymentApproveRejectButtonStatus(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
  configService: ConfigService,
) {
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  //get system config from form state
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);
  if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
    const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
    );
    const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
    );
    const isPaymentApprover = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
    );
    const data = store.select(BulkPaymentListState.getBulkPaymentList);
    data.subscribe({
      next: (res) => {
        const masterStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
        let isHideButton = false;
        if (isPaymentApprover) {
          if (
            masterStatus &&
            [
              BulkPaymentMasterStatus.APPROVED,
              BulkPaymentMasterStatus.CANCELLED,
              BulkPaymentMasterStatus.REJECTED,
              BulkPaymentMasterStatus.COMPLETED,
              BulkPaymentMasterStatus.AUTHENTICATED,
            ].includes(masterStatus)
          ) {
            isHideButton = true;
          }
        } else if (isPaymentRequestor || isPaymentInitiator) {
          isHideButton = true;
        }
        const key = attri?.['buttonActionType'] as string;
        ctaButtonSignalService.setHideButtonSignal({
          [key]: isHideButton,
        });
      },
    });
  }
}
