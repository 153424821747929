<finxone-web-frontend-international-beneficiary-credentials
  [recipientName]="recipientName"
  [firstName]="firstName"
  [lastName]="lastName"
  [addressRequired]="addressRequired"
  [beneficiaryType]="beneficiaryType"
  [continueButtonContent]="continueButtonContent"
  [continueButtonStyle]="continueButtonStyle"
  [previousButtonContent]="previousButtonContent"
  [previousButtonStyle]="previousButtonStyle"
  [nextButtonContent]="nextButtonContent"
  [nextButtonStyle]="nextButtonStyle"
  [contentStyle]="contentStyle"
  [inputStyle]="inputStyle"
  [inputBaseStyle]="inputBaseStyle"
  [inputInactiveStyle]="inputInactiveStyle"
  [textInputFontStyle]="textInputFontStyle"
  [widgetProperties]="widgetProperties"
  [buttonGlobalStyles]="buttonGlobalStyles"
></finxone-web-frontend-international-beneficiary-credentials>
