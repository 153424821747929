import { PaymentRequestPayload } from '@finxone-platform/shared/utils';
import {
  PaymentRequestUpdateRequest,
  PaymentRequestorRequest,
} from '../services/account-service/account.type';

export class CreatePaymentRequest {
  static readonly type = '[Payment] CreatePaymentRequest';
  constructor(public paymentRequest: PaymentRequestorRequest) {}
}

export class PaymentRequestSuccess {
  static readonly type = '[Payment] PaymentRequestSuccess';
}

export class PaymentRequestFailed {
  static readonly type = '[Payment] PaymentRequestFailed';
}

export class UpdatePaymentRequest {
  static readonly type = '[Payment] UpdatePaymentRequest';
  constructor(public paymentRequest: PaymentRequestUpdateRequest) {}
}

export class UpdatePaymentRequestSuccess {
  static readonly type = '[Payment] UpdatePaymentRequestSuccess';
}

export class UpdatePaymentRequestFailed {
  static readonly type = '[Payment] UpdatePaymentRequestFailed';
}

export class GetPaymentRequest {
  static readonly type = '[Payment] GetPaymentRequest';
  constructor(public page = 1, public limit = 5, public status = '', public searchValue = '') {}
}
export class GeneratePaymentRequest {
  static readonly type = '[Payment] GeneratePaymentRequest';
  constructor(public payload: PaymentRequestPayload) {}
}

export class GeneratePaymentRequestPayload {
  static readonly type = '[Payment] GeneratePaymentRequestPayload';
  constructor(public payload: PaymentRequestPayload) {}
}

export class ResetPaymentRequestPayload {
  static readonly type = '[Payment] ResetPaymentRequestPayload';
}
