//Setting overflow to hidden to ensure no scrollbar appear on custom grid
export function setOverFlowHiddenOnBodyTag() {
  const body = document.getElementById('web-app');
  if (body) {
    body.style.overflow = 'hidden';
  }
}

export function setOverFlowAutoOnBodyTag() {
  const body = document.getElementById('web-app');
  if (body) {
    body.style.overflow = 'auto';
  }
}
