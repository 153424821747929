import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageConfigurationProvider } from '@app/finxone-web-frontend/app/Modules/zones/page-configuration.provider';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import { PasswordService } from '@app/finxone-web-frontend/app/lib/services/password-service/password-service.service';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import {
  BaseCssProperties,
  BaseWidgetProperties,
  ProviderClass,
  WidgetNames,
  baseCssConfigStyle,
  buttonNestedWidgetConfigStyles,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { FormActionTypeEnum } from '../../../../../../../../libs/shared/enums/form-action.enum';
import {
  FormStage,
  MultiPartFormHolderService,
} from '../../../../lib/services/multipart-holder/multipart-form-holder.service';
import { ZonePageUrlProvider } from '../../../zones/zone-url-provider';
import { FormSubmitter } from '../form-submitter/form-submitter.component';

@Component({
  selector: 'finxone-web-frontend-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss'],
})
export class PasswordForgotComponent extends FormSubmitter implements OnInit {
  public buttonContent = '';
  public renderedButtonContent = '';
  public zone = '';
  public enableSubmit = false;
  public nextPageUrl: string;
  public passwordForm: FormGroup;
  public reviewDetailsButtonStyle: baseCssConfigStyle = {};
  public newPasswordInputStyle: baseCssConfigStyle;
  public confirmPasswordInputStyle: baseCssConfigStyle;
  public inputActiveStyle: baseCssConfigStyle;
  public inputInactiveStyle: baseCssConfigStyle;
  public globalStylingClass = '';
  public isAllowedGlobalStyling = false;
  public colorTextAlignStyle: baseCssConfigStyle;

  constructor(
    private _router: Router,
    public pageConfig: PageConfigurationProvider,
    public dataIn: ProviderClass<any>,
    private configService: ConfigService,
    public override store: Store,
    public formHolderService: MultiPartFormHolderService,
    private formSubmissionService: FormSubmissionService,
    private passwordService: PasswordService,
    private urlZoneInput: ZonePageUrlProvider,
  ) {
    super(store);
    this.colorTextAlignStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.FONT_SIZE,
      BaseCssProperties.TEXT_BOLD,
      BaseCssProperties.TEXT_ITALIC,
      BaseCssProperties.TEXT_UNDERLINE,
      BaseCssProperties.COLOR,
      BaseCssProperties.TEXT_FONT_STYLE,
      BaseCssProperties.TEXTALIGN,
    ]);
    this.contentStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.BORDER,
      BaseCssProperties.BACKGROUND_COLOR,
    ]);
    this.getAndAssignInputFieldStyles(this.dataIn.attri.widgetProperties);

    this.zone = this.urlZoneInput.zoneUrlInput;
  }

  ngOnInit(): void {
    this.createFormStage();
    this.buildForm();
    this.buttonContent =
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton']?.widgetProperties.textContent ?? 'Next';

    this.reviewDetailsButtonStyle = this.configService.getSpecificBaseCssStyle(
      this.getNestedWidgetConfig(this.dataIn, 'reviewDetailsButton'),
      buttonNestedWidgetConfigStyles,
    );
    this.getGlobalStyling();
  }

  private getGlobalStyling() {
    if (
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.[
        'globalStylingClass'
      ] &&
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.['globalStyling']
    ) {
      this.globalStylingClass =
        this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.[
          'globalStylingClass'
        ] ?? '';
      this.isAllowedGlobalStyling =
        this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.['globalStyling'] ??
        false;
    }
  }

  private createFormStage(): void {
    const formStage = new FormStage({
      position: 0,
      name: WidgetNames.PASSWORD_FORGOT,
      route: '',
      form: new FormGroup({
        newPassword: new FormControl('', Validators.required),
        confirmPassword: new FormControl('', Validators.required),
      }),
    });
    this.formSubmissionService.addCurrentPageFormGroups(formStage.form);
    this.formHolderService.config.addStage(formStage);
  }

  private buildForm(): void {
    const stage = this.formHolderService.config.getStage(WidgetNames.PASSWORD_FORGOT);
    this.passwordForm = stage.getForm();
  }

  public onSubmit(): void {
    if (this.passwordForm.valid) {
      const formValues: Record<string, any> = this.passwordForm.value;

      this.formSubmissionService
        .submitIndividualForm(formValues, '', FormActionTypeEnum.PASWORD_CHANGE)
        .then((success) => {
          if (success) {
            this.store
              .select(FormActionState.getFormActionState)
              .pipe(
                map((formActionStateData) => {
                  return formActionStateData[FormActionTypeEnum.PASWORD_CHANGE]?.formData;
                }),
              )
              .subscribe({
                next: ({ currentPassword, newPassword, confirmPassword }) => {
                  if (this.enableSubmit) {
                    if (this.dataIn.widgetConfig.name === WidgetNames.PASSWORD_UPDATE) {
                      this.passwordService
                        .resetPassword(currentPassword, newPassword, confirmPassword)
                        .subscribe({
                          next: () => {
                            this._router.navigate(['/zones/profile/change-password-success']);
                          },
                          error: (err) => {
                            console.error(err);
                          },
                        });
                    } else {
                      const splitUrl = window.location.href.split('/');
                      const token = splitUrl[splitUrl.length - 1];
                      this.passwordService.updatePassword(token, newPassword, confirmPassword).subscribe({
                        next: () => {
                          this._router.navigate(['/zones/forgot-password/pass-reset-validation']);
                        },
                        error: (err) => {
                          console.error(err);
                        },
                      });
                    }
                    this.enableSubmit = false;
                  }
                },
              });

            let name = '';
            Object.values(formValues).forEach((value: string) => {
              name = name + value + ' ';
            });
          } else {
            return;
          }
        });
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  private getAndAssignInputFieldStyles(widgetProperties: BaseWidgetProperties) {
    const { baseStyle, activeStyle, inactiveStyle } =
      this.configService.getInputFieldCssStyles(widgetProperties);
    this.inputActiveStyle = {
      ...baseStyle,
      ...activeStyle,
    };
    this.inputInactiveStyle = {
      ...inactiveStyle,
      ...activeStyle,
    };
    this.newPasswordInputStyle = this.confirmPasswordInputStyle = this.inputInactiveStyle;
  }

  public updateInputFieldCurrentStyles(event: boolean, fieldName: string) {
    if (fieldName === 'newPassword') {
      this.newPasswordInputStyle = event ? this.inputActiveStyle : this.inputInactiveStyle;
    }
    if (fieldName === 'confirmPassword') {
      this.confirmPasswordInputStyle = event ? this.inputActiveStyle : this.inputInactiveStyle;
    }
  }
}
