export const noMatch =
  "The account details you gave us aren't the same as the details in the banking records.";

export const noResponse = 'We received no response whilst checking these details.';

export const optedOut = 'The account with the details provided has opted out of this service.';

export const accountNotSupported = 'The account with the details provided is not supported.';

export const accountSwitched = 'The account with the details provided has been switched.';

export const sortCodeNotSupported =
  'The sort code provided has not been recognised. Please check the account details';

export const accountNumberIncorrect =
  'The account details supplied do not exist. Please check details with recipient.';

export const invalidSecondaryIdentifier = 'The secondary identifier provided is invalid.';

export const serviceUnavailable = `The check cannot be performed right now. Please click 'Back' to try again, or click 'Continue anyway' if you are sure the details are correct. Proceed with caution`;
