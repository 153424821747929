import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceLocalData } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { KeycloakWrapperService } from './services/keycloak-wrapper-service/keycloak-wrapper.service';
import { ProfileState } from './state/user-profile.state';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  constructor(
    private _route: Router,
    private store: Store,
    private keycloakService: KeycloakWrapperService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedToken = this.keycloakService.getKeycloakInstance()?.token
      ? `Bearer ${this.keycloakService.getKeycloakInstance()?.token}`
      : localStorage.getItem('token')
      ? `Bearer ${localStorage.getItem('token')}`
      : '';
    const deviceInfo = localStorage.getItem('deviceInfo');
    let deviceData: DeviceLocalData;
    try {
      deviceData = JSON.parse(deviceInfo ?? '{}');
    } catch (error) {
      deviceData = {} as DeviceLocalData;
    }
    const deviceManufacturer = deviceData?.manufacturer ?? '';
    const deviceOS = deviceData?.os ?? '';
    const deviceModel = deviceData?.deviceModel ?? '';
    const deviceId = deviceData?.deviceId ?? '';
    const profileData = this.store.selectSnapshot(ProfileState.getProfile);
    const organisation = profileData?.activeOrganisationId ?? '';
    const role = profileData?.activeRole ?? '';
    let authHeaderValue = undefined;

    if (
      new RegExp(window.location.hostname).exec(req.url) ||
      /finxone.com/.exec(req.url) ||
      /localhost/.exec(req.url)
    ) {
      authHeaderValue = {
        Authorization: updatedToken,
      };
    } else {
      authHeaderValue = {};
    }

    const body = req.body;
    let otpValue: string | undefined;

    if (body && 'otp' in body) {
      otpValue = body.otp; // Extract the OTP from the body
      delete body.otp; // Remove OTP from the body
    }

    req =
      req.url.includes('file/file-upload') ||
      req.url.includes('/receipt') ||
      req.url.includes('/document') ||
      req.url.includes('/ticket') ||
      req.url.includes('/passports')
        ? req.clone({
            setHeaders: {
              ...authHeaderValue,
              token: updatedToken,
              organisation: organisation,
              role: role,
              'X-Finxone-Org-Id': organisation,
              'X-Finxone-Role': role,
              'X-Finxone-User-Agent': window.navigator.userAgent,
              'X-Finxone-Device-Id': deviceId,
              'X-Finxone-Device-Manufacture': deviceManufacturer,
              'X-Finxone-Device-Os': deviceOS,
              'X-Finxone-Device-Model': deviceModel,
              Accept: '*/*',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET/POST/PUT/DELETE',
            },
          })
        : req.clone({
            setHeaders: {
              ...authHeaderValue,
              token: updatedToken,
              organisation: organisation,
              role: role,
              Accept: 'application/json',
              'X-Finxone-Org-Id': organisation,
              'X-Finxone-Role': role,
              ...(otpValue ? { 'X-Finxone-otp': otpValue } : {}),
              'X-Finxone-User-Agent': window.navigator.userAgent,
              'X-Finxone-Device-Id': deviceId,
              'X-Finxone-Device-Manufacture': deviceManufacturer,
              'X-Finxone-Device-Os': deviceOS,
              'X-Finxone-Device-Model': deviceModel,
              'Content-Type': 'application/json; charset=utf-8',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET/POST/PUT/DELETE',
            },
          });
    return next.handle(req);
  }
}
