import { ActivatedRoute, Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ACCOUNTTYPE, APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, map, take } from 'rxjs';
import { UpdateBeneficiary } from '../../../actions/beneficiary.action';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
  AddBeneficiaryRequest,
  UpdateBeneficiaryRequest,
} from '../../../services/account-service/account.type';
import { BackOverrideService } from '../../../services/back-override-service/back-override.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function addEditBeneficiary(
  store: Store,
  router: Router,
  accountService: AccountService,
  route: ActivatedRoute,
  alertHandlerService: AlertHandlerService,
  backService: BackOverrideService,
) {
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);
  route?.queryParams.pipe(take(1)).subscribe((params) => {
    const beneficiaryFlowParam = params['flow'];

    if (beneficiaryFlowParam === 'bulk-payment') {
      callContinueAnywayAPI(store, accountService, alertHandlerService, router, backService);
      return;
    }

    if (beneficiaryFlowParam === OtpParam.AddBeneficiary) {
      const formActionStateData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_BENEFICIARY),
      );
      const formData = formActionStateData?.formData as AddBeneficiaryRequest;
      if (projectSettingsData?.smsAuthenticationEnabled) {
        if (projectSettingsData.smsAuthenticationExclusion?.['addBeneficiary']?.includes(roleData?.role)) {
          // previous scenario
          callAddBeneficiaryAPI(accountService, router, formData, alertHandlerService, backService);
        } else {
          // new OTP page redirection
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/authentication-sms?otp=${OtpParam.AddBeneficiary}`,
          );
        }
      } else {
        // previous scenario
        callAddBeneficiaryAPI(accountService, router, formData, alertHandlerService, backService);
      }
    } else {
      const formActionStateData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
      );
      const formData = formActionStateData?.formData?.payload as UpdateBeneficiaryRequest;
      if (formData?.id) {
        if (projectSettingsData?.smsAuthenticationEnabled) {
          if (projectSettingsData.smsAuthenticationExclusion?.['editBeneficiary']?.includes(roleData?.role)) {
            // previous scenario
            store.dispatch(new UpdateBeneficiary(formData, formData.id));
          } else {
            // OTP page redirection
            router.navigateByUrl(
              `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/authentication-sms?otp=${
                OtpParam.EditBeneficiary
              }`,
            );
          }
        } else {
          // previous scenario
          store.dispatch(new UpdateBeneficiary(formData, formData.id));
        }
      }
    }
  });
}

function callAddBeneficiaryAPI(
  accountService: AccountService,
  router: Router,
  formData: AddBeneficiaryRequest,
  alertHandlerService: AlertHandlerService,
  backService: BackOverrideService,
) {
  accountService
    .addBeneficiary(formData)
    .pipe(
      take(1),
      map(() => {
        const nextRoute = `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/select-beneficiary`;
        router.navigateByUrl(nextRoute);
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        alertHandlerService.showAlertFn(
          'success',
          'The beneficiary has been successfully added.',
          'Beneficiary created!',
        );
      }),
      catchError(() => {
        const endRoute =
          formData.accountType === ACCOUNTTYPE.PERSONAL
            ? 'add-beneficiary-personal'
            : 'add-beneficiary-business';
        alertHandlerService.showAlertFn(
          'error',
          'There was an error creating the beneficiary. Try again.',
          'Failed to create beneficiary!',
        );
        return router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${endRoute}`);
      }),
    )
    .subscribe();
}

const callContinueAnywayAPI = (
  store: Store,
  accountService: AccountService,
  alertHandlerService: AlertHandlerService,
  router: Router,
  backService: BackOverrideService,
) => {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const masterFileId = formActionData?.formData.masterFileId;
  const transactionId = formActionData?.formData.transactionId;

  // passing suggestion: false as we call the continue anyway function here
  accountService
    .bulkPaymentContinueAnywayAndWithSuggestion(masterFileId, transactionId, { suggestion: false })
    .pipe(
      take(1),
      map(() => {
        store.dispatch(new ClearBulkPaymentList());
        store.dispatch(new GetBulkPaymentList(1, 10, masterFileId));
        const nextRoute = `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-beneficiary-review`;
        router.navigateByUrl(nextRoute);
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        alertHandlerService.showAlertFn('success', 'Updated Successfully.');
      }),
      catchError((error) => {
        alertHandlerService.showAlertFn('error', 'There was an error ');
        return error;
      }),
    )
    .subscribe();
};
