export enum FormActionTypeEnum {
  PASWORD_CHANGE = 'passwordChange',
  INTL_BENEFICIARY_ADD = 'intlBeneficiaryAdd',
  FACILITY_DETAILS = 'facilityDetails',
  EVENT_DETAILS = 'eventDetails',
  KYC_DETAILS = 'kycDetails',
  KYB_DETAILS = 'kybDetails',
  CUSTOM_QUESTION = 'customQuestion',
  EXCHANGE_QUOTE_SUMMARY = 'exchangeQuoteSummary',
  INTL_TRANSFER_RESPONSE = 'intlTransferResponse',
  MANAGE_USER = 'manageUser',
  MANAGE_USER_EDIT = 'manageUserEdit',
  ADD_EMPLOYEE = 'addEmployee',
  EMPLOYEE_DETAILS = 'employeeDetails',
  BULK_PAYMENTS = 'bulkPayments',
  PROFILE_BANK_STATEMENT = 'profileBankStatement',
  ADD_TRIP_REVENIR_ZONE = 'addTripRevenirZone',
  ADD_FEE_GROUP = 'addFeeGroup',
  ADD_EDIT_FEE = 'addEditFee',
  EDIT_PROFILE_DETAILS = 'editProfileDetails',
  CURRENCY_EXCHANGE = 'currencyExchange',
  EDIT_BENEFICIARY = 'editBeneficiary',
  ADD_BENEFICIARY = 'addBeneficiary',
}
