import {
  SystemRoles,
  SystemSystemConfig,
  ThemeConfig,
  buildThemeCssVarsObject,
} from '@finxone-platform/shared/sys-config-types';
import { formatNameAsUrl } from './zone-url.utils';

export function setupNewThemeing(theme: ThemeConfig) {
  const cssVarsToSet = buildThemeCssVarsObject(theme);
  const rootStyle = document.documentElement.style;
  Object.entries(cssVarsToSet).forEach(([key, value]) => {
    rootStyle.setProperty(key, value);
  });
}

export function loadStyleSheet(themeUrl: string) {
  if (document.getElementById('globalTheme')) {
    document.getElementById('globalTheme')?.remove();
  }
  const styleElement = document.createElement('link');
  styleElement.setAttribute('id', 'globalTheme');
  styleElement.setAttribute('rel', 'stylesheet');
  styleElement.setAttribute('href', `${themeUrl}`);
  // Append the style element to the document's head
  document.head.appendChild(styleElement);
}

export function setupThemeing(role: string, system: SystemSystemConfig, systemRoles: SystemRoles) {
  const roleSlug = formatNameAsUrl(role);
  // TODO once we support multiple themes on one persona this needs to change
  const roleTheme = systemRoles[roleSlug]?.alternativeThemes?.find(
    (theme) => theme.isPrimary === true,
  )?.value;

  if (roleTheme && roleTheme !== 'default') {
    if (system.alternativeThemes) {
      const theme = system.alternativeThemes[roleTheme].theme;

      setupNewThemeing(theme);
    } else {
      setupNewThemeing(system.theme);
    }
  } else {
    setupNewThemeing(system.theme);
  }
}
