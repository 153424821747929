export class SetCopResponseStateName {
  static readonly type = '[CopResponse] SetCopResponseStateName';
  constructor(public payload: string) {}
}

export class SetCopResponseStateReason {
  static readonly type = '[CopResponse] SetCopResponseStateReason';
  constructor(public payload: string) {}
}
export class SetCopResponseStateMatchStatusCode {
  static readonly type = '[CopResponse] SetCopResponseStateMatchStatusCode';
  constructor(public payload: string) {}
}
