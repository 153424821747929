import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { KeycloakService } from 'keycloak-angular';
import { AccountService } from '../../services/account-service/account-service.service';
import { DeviceTypeService } from '../../services/device-type/device-type.service';
import { formatNameAsUrl } from '../../utils/zone-url.utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    protected keycloakService: KeycloakService,
    private _router: Router,
    accoutService: AccountService,
    private deviceTypeService: DeviceTypeService,
  ) {
    if (localStorage.getItem('refreshToken') && localStorage.getItem('token')) {
      accoutService.setRoleInfo(localStorage.getItem('token') as string);
      this._router.navigateByUrl('/feature-redirect');
    } else {
      const firstTimeComplete = localStorage.getItem('firstTime');
      if (this.deviceTypeService.currentdeviceType == 'desktop') {
        this.keycloakService.login({
          redirectUri: window.location.origin,
        });
      } else {
        if (firstTimeComplete == null) {
          localStorage.setItem('firstTime', 'true');
          _router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION)]);
        } else {
          _router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION)]);
        }
      }
    }
  }

  login() {
    this.keycloakService.login({
      redirectUri: window.location.origin + '/feature-redirect',
    });
  }
}
