export const revenirTransactionHistoryItemTemplate = `
            <div class="ml-1 mr-1">
              <h4 class="text-left m-0 pl-2 font-weight-bold">{{ merchant_name }}</h4>
            </div>

            {{#if (eq receipt_status 'Accepted')}}
              <div class="mr-2 text-right">
                  <h5 class="m-0">
                   <b>
                    {{ currencyPipe total_amount currencyCode=currency display='symbol-narrow' }}
                   </b>
                     <br>
                    <h6 class="typo-color-1">
                     VAT Reclaim {{ currencyPipe total_vat_amount currencyCode=currency display='symbol-narrow' }}
                    </h6>
                  </h5>
               </div>
            {{else if (eq receipt_status 'Rejected')}}

             <div class="mr-2 text-right">
                  <h6 class="m-0 typo-color-1">
                   VAT not reclaimable
                  </h6>
              </div>

            {{else}}

             <div class="is-right mr-2">
                <h5 class="text-right m-0 typo-color-1">
                  {{ receipt_status }}
                </h5>
              </div>

            {{/if}}

`;

export const revenirQuickLinkTemplate = `
See More
`;

export const revenirTransactionTitleTemplate = `
Trip Transactions
`;
export const revenirDebitImage =
  'PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjU2IiBoZWlnaHQ9IjU2IiByeD0iMjAiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDU2IDApIiBmaWxsPSIjRkVDRENBIi8+Cjwvc3ZnPgo=';

export const revenirCreditImage =
  'PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjU2IiBoZWlnaHQ9IjU2IiByeD0iMjAiIGZpbGw9IiNFM0U4RjMiLz4KPC9zdmc+Cg==';

export const revenirTransactionItemTemplate = `
        <div class="ml-1 mr-1">

            {{#if eligible}}
                  <span class="typog-md text-left m-0 font-weight-bold">{{ item_description }}</span>
                    <br>
                  <span class="typog-md"> <b>
                    {{ currencyPipe total currencyCode=currency display='symbol-narrow' }}
                  </b> </span>

                    <br>
                   <span class="typog-xs typo-color-1 m-0">
                          VAT Reclaim
                    </span>
                      <br>
                   <span class="typog-xs typo-color-1 m-0">
                    {{ currencyPipe vat_amount currencyCode=currency display='symbol-narrow' }}
                   </span>
            {{else}}
                <div class="disable">
                    <span class="typog-md text-left m-0">{{ item_description }}</span>
                      <br>
                    <span class="typog-md">
                        {{ currencyPipe total currencyCode=currency display='symbol-narrow' }}
                     </span>

                      <br>
                    <span class="typog-xs typo-color-1 m-0">
                          VAT Reclaim
                    </span>
                      <br>
                    <span class="typog-xs typo-color-1 m-0">
                      {{ currencyPipe vat_amount currencyCode=currency display='symbol-narrow' }}
                    </span>
                </div>

            {{/if}}

        </div>
`;

export const revenirTransactionItemTitleTemplate = `
Items
`;
