import { ACCOUNTTYPE } from '../account-type.enum';
import { WidgetNames, defaultZoneConfiguration } from '../config.type';
import { PRIVACY_SLUG } from '../privacy.enum';
import { APP_ZONES, CanvasZone, PAGELAYOUTTYPE, RightWidgetConfiguration } from '../workflow.type';
import { CommonPageConfig, WorkflowPageConfig, WorkflowZoneDevicesConfig } from '../workflow.v2.type';
import { ZoneInfo } from '../zone-info.utils';
import { Constants } from './workflow-constants.type';

export function defaultCanvas(): CanvasZone {
  return {
    canvases: {
      name: 'Canvas',
      subscription: {
        default: {
          zoneDevicesTypeConfig: 'new',
          mobile: {
            zoneTypeConfig: 'new',
            zoneConfiguration: defaultZoneConfiguration,
            url: '',
            pages: [buildEmptyPageConfigForDynamicGridPage('Page 1', 'Page 1')],
          },
          desktop: {
            zoneTypeConfig: 'new',
            url: '',
            zoneConfiguration: defaultZoneConfiguration,
            pages: [buildEmptyPageConfigForDynamicGridPage('Page 1', 'Page 1')],
          },
          tablet: {
            zoneTypeConfig: 'new',
            zoneConfiguration: defaultZoneConfiguration,
            url: '',
            pages: [buildEmptyPageConfigForDynamicGridPage('Page 1', 'Page 1')],
          },
        } as WorkflowZoneDevicesConfig,
      },
    },
  };
}

export const commonPageConfigValue = {
  gridsterMargin: {
    left: 1,
    right: 1,
    top: 2,
    unit: 'em',
  },
  showHeader: true,
  labelsAsPlaceholder: false,
  backgroundColor: 'var(--palette-color-6)',
  backgroundImage: {
    image: '',
    opacity: '1',
  },
  backgroundPosition: {
    x: 'unset',
    y: 'unset',
  },
  backgroundSize: 'auto',
  backgroundGradient: '',
  fontsConfig: {
    h1: {
      'font-family': 'var(--font-family)',
      'font-weight': 'regular',
    },
    h2: {
      'font-family': 'var(--font-family)',
      'font-weight': 'regular',
    },
    h3: {
      'font-family': 'var(--font-family)',
      'font-weight': 'regular',
    },
    bodyFont: {
      'font-family': 'var(--font-family)',
      'font-weight': 'regular',
    },
  },
};

export function buildEmptyPageConfigForDynamicGridPage(
  pageName: string,
  pageTitle: string,
  pageConfiguration: CommonPageConfig = {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: true,
    url: formatNameAsUrl(pageName),
    pageType: 'dynamicGrid',
    ...commonPageConfigValue,
  },
): WorkflowPageConfig {
  return {
    name: pageName,
    title: pageTitle,
    pageConfiguration: pageConfiguration,
    widgets: [],
    pagesStates: [],
  };
}
/**
 * To remove hyphen, underscore from zone or page name.
 * @param ParamName - zone or page name
 * @param type - zone or page
 */
function formatNameAsUrl(paramName: string | APP_ZONES): string {
  if (!paramName) {
    return '';
  }
  const words = paramName.split(/[-_ ]/);
  const formattedString = words
    .map((word) => word.toLowerCase())
    .filter((word) => word !== 'zone')
    .join('-');
  return formattedString;
}

export const defaultModules = {
  [APP_ZONES.ONBOARDING]: {
    zoneType: 'singlePage',
    url: '',
    zoneName: APP_ZONES.ONBOARDING,
    type: PAGELAYOUTTYPE.WIDGET,
    mainComponentPageName: 'AccountRegistrationComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.LANDING]: {
    url: 'individual-account-page',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.LANDING,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.PAYMENT]: {
    url: '',
    zoneType: 'multiPage',
    zoneName: APP_ZONES.PAYMENT,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ProfileWidgetIntegrationComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.PROFILE]: {
    url: '',
    zoneType: 'multiPage',
    zoneName: APP_ZONES.PROFILE,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ProfileWidgetIntegrationComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.CHART]: {
    url: 'spendings',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.CHART,
    type: PAGELAYOUTTYPE.WIDGET,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.USER_MANAGEMENT]: {
    url: 'users',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.USER_MANAGEMENT,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.GENERAL]: {
    url: 'general',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.GENERAL,
    type: PAGELAYOUTTYPE.WIDGET,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.MENU_ZONE]: {
    url: 'menu',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.MENU_ZONE,
    type: PAGELAYOUTTYPE.WIDGET,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.CARD_MANAGEMENT_ZONE_DEMO]: {
    url: 'card-management',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.CARD_MANAGEMENT_ZONE_DEMO,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: { isSeparateZone: true },
  },
  [APP_ZONES.VERIFICATION]: {
    url: '',
    zoneType: 'multiPage',
    zoneName: APP_ZONES.VERIFICATION,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'UserPendingVerificationComponent',
    extraComponentAttributes: {},
  },
  sumsub: {
    url: '',
    type: PAGELAYOUTTYPE.SECTION,
    zoneType: 'multiPage',
    zoneName: APP_ZONES.VERIFICATION,
    mainComponentPageName: 'SumsubWidgetIntegrationComponent',
    extraComponentAttributes: {},
  },
  default: {
    url: '',
    zoneType: 'singlePage',
    zoneName: '',
    type: PAGELAYOUTTYPE.WIDGET,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.BROKER_EVENTS]: {
    url: 'broker-events',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.BROKER_EVENTS,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.BROKER_FACILITY]: {
    url: 'broker-facility',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.BROKER_FACILITY,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.BROKER_LANDING]: {
    url: 'broker-landing',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.BROKER_LANDING,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
  [APP_ZONES.BROKER_EMPLOYEE]: {
    url: 'broker-employee',
    zoneType: 'singlePage',
    zoneName: APP_ZONES.BROKER_EMPLOYEE,
    type: PAGELAYOUTTYPE.SECTION,
    mainComponentPageName: 'ParentContainerComponent',
    extraComponentAttributes: {},
  },
} as defaultModules;

export type defaultModules = {
  [key: string]: ZoneInfo;
};

export type widgetConfigResetType = {
  [key: string]: RightWidgetConfiguration;
};

export const getWidgetConfigForReset = {
  // signup widgets

  [WidgetNames.PROGRESSBAR_WIDGET]: {
    attributes: {
      widgetProperties: {
        completionProgressPercentage: '5',
        progressBarActiveColor: 'var(--palette-color-0)',
        progressBarColor: 'var(--palette-color-6)',
      },
      elementsConfig: [
        {
          field_name: WidgetNames.PROGRESSBAR_WIDGET,
          label: 'Image.png',
          type: 'file',
          section: WidgetNames.PROGRESSBAR_WIDGET,
          show: true,
          options: [],
        },
      ],
      field_attributes: [],
      css_config: {
        color: 'var(--palette-color-4)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        fontSize: 25,
        isTextBold: true,
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.BIRTHDAY_WIDGET]: {
    elementsConfig: [
      {
        field_name: 'min_age',
        type: '18',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.PHONE_WIDGET]: {
    elementsConfig: [
      {
        field_name: 'verificationViaOTP',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.EMAIL_WIDGET]: {
    elementsConfig: [
      {
        field_name: 'verificationViaOTP',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.MOT_VERIFICATION]: {
    elementsConfig: [
      {
        options: [
          {
            show: false,
          },
        ],
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.COMPANY_HOUSE_CHECK]: {
    elementsConfig: [
      {
        options: [
          {
            show: true,
          },
        ],
      },
    ],
  } as RightWidgetConfiguration,

  // Display Widgets

  [WidgetNames.LINE_WIDGET]: {
    attributes: {
      field_attributes: [
        {
          additional_config: {
            show: true,
            color: 'var(--typography-color-0)',
            paddingTop: 20,
            paddingBottom: 20,
            lineThickness: 1,
            lineWidth: 90,
            contentAlignment: 'center',
          },
        },
      ],
    },
  } as RightWidgetConfiguration,

  [WidgetNames.IFRAME_WIDGET]: {
    attributes: {
      css_config: {
        iframeUrl: '',
        verticalHeight: '30',
        showIFrameWidget: true,
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.CAROUSEL_WIDGET]: {
    elementsConfig: [
      {
        timer: 3,
      },
    ],
  } as RightWidgetConfiguration,

  // kyc

  [WidgetNames.VERIFICATION]: {
    elementsConfig: [
      {
        field_name: 'kycVerficationEmail',
        show: true,
      },
      {
        field_name: 'kycVerficationPhone',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.CTA_BUTTON_WIDGET]: {
    attributes: {
      widgetProperties: {
        textContent: 'KYC ME!',
      },
    },
  } as RightWidgetConfiguration,

  // landing page

  [WidgetNames.ACCOUNT_BALANCE_WIDGET]: {
    elementsConfig: [
      {
        field_name: 'showCardDetails',
        show: true,
      },
      {
        field_name: 'showCardBalance',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET]: {
    elementsConfig: [
      {
        field_name: 'showMonthlyIncome',
        show: true,
      },
      {
        field_name: 'showMonthlyExpenditure',
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.CARD_ACTIVATION]: {
    elementsConfig: [
      {
        field_name: 'activationMessage',
        placeholder: 'Click here to activate card',
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.PAYMENT_BUTTON]: {
    attributes: {
      css_config: {
        backGroundColor: 'var(--palette-color-0)',
        color: 'var(--palette-color-6)',
        textAlignment: 'center',
        verticalAlignment: 'center',
      },
      widgetProperties: {
        textContent: 'Payments Button',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.QUICK_LINKS_WIDGET]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      widgetProperties: {
        textContent: 'Quick Links',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'left',
        verticalAlignment: 'center',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      widgetProperties: {
        textContent: 'Multi Icon Quick Link ',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.BACK_BUTTON_WIDGET]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        iconSize: 24,
        borderWidth: 1,
      },
      widgetProperties: {
        textContent: 'Back',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.FORM_SUBMISSION_BUTTON]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        backGroundColor: 'var(--color-primary)',
        color: 'var(--color-surface)',
        fontSize: 16,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      widgetProperties: {
        textContent: 'CTA',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.ONBOARDING_BACK_NEXT_BUTTON]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--color-primary)',
        color: 'var(--color-surface)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
      },
      widgetProperties: {
        textContent: 'Next',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.MANDATORY_CTA_BUTTON]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        backGroundColor: 'var(--color-primary)',
        color: 'var(--color-surface)',
        fontSize: 16,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      widgetProperties: {
        textContent: 'CTA',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.WIDGET_NESTED_BUTTON]: {
    attributes: {
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        backGroundColor: 'var(--color-primary)',
        color: 'var(--color-surface)',
      },
      widgetProperties: {
        textContent: 'Button',
        icon: '',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.H1_WIDGET]: {
    attributes: {
      css_config: {
        textAlignment: 'center',
        verticalAlignment: 'center',
        fontSize: '32',
        color: 'var(--typography-color-0)',
      },
      widgetProperties: {
        textContent: 'Header Text',
      },
    },
  } as RightWidgetConfiguration,

  [WidgetNames.TEXT_WIDGET]: {
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        fontSize: '16',
        color: 'var(--typography-color-0)',
      },
      widgetProperties: {
        textContent: 'Text / HTML',
      },
    },
  } as RightWidgetConfiguration,

  // profile page

  [WidgetNames.PROFILE_PAGE]: {
    elementsConfig: [
      {
        field_name: PRIVACY_SLUG.TERMS,
        show: true,
      },
      {
        field_name: PRIVACY_SLUG.PRIVACY_POLICY,
        show: true,
      },
    ],
  } as RightWidgetConfiguration,

  [WidgetNames.SIMPLE_BALANCE_WIDGET]: {
    attributes: {
      css_config: Constants.defaultSimpleBalanceBaseCssConfig,
    },
  } as RightWidgetConfiguration,
  [WidgetNames.BENEFICIARY_CAROUSEL]: {
    attributes: {
      css_config: Constants.defaultBeneficiaryCarouselBaseCssConfig,
    },
  } as RightWidgetConfiguration,
  // menu zone
} as widgetConfigResetType;

const blankDestinationIdentifiers = {
  accountNumber: '',
  sortCode: '',
  currency: '',
  country: '',
  iban: '',
  bic: '',
  aba: '',
  rtnCanada: '',
  clabe: '',
  bsbCode: '',
  ifsc: '',
  cnaps: '',
};
export const defaultBeneficiaryList = [
  {
    id: '1',
    name: 'Backling Jr.',
    accountType: ACCOUNTTYPE.PERSONAL,
    destinationIdentifier: blankDestinationIdentifiers,
  },
  {
    id: '2',
    name: 'Frank Fisher',
    accountType: ACCOUNTTYPE.PERSONAL,
    destinationIdentifier: blankDestinationIdentifiers,
  },
  {
    id: '3',
    name: 'Mr Chapman',
    accountType: ACCOUNTTYPE.BUSINESS,
    destinationIdentifier: blankDestinationIdentifiers,
  },
];
