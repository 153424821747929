import { Injectable, NgZone } from '@angular/core';
import { ConfigService } from '../config-service/config-service.service';
import { KeycloakWrapperService } from '../keycloak-wrapper-service/keycloak-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private timeoutId: any;
  private idleTime = 300 * 1000; // 5 minute in milliseconds

  constructor(
    private ngZone: NgZone,
    private keycloakService: KeycloakWrapperService,
    private configService: ConfigService,
  ) {
    this.configService.getConfig().subscribe((config) => {
      // Additional logic to handle config
      const refreshToken = this.keycloakService.getKeycloakInstance().refreshTokenParsed;

      // take the timeout value from the refreshToken validity period
      // prefer the idleTimeout setting if it's set
      const refreshTokenValidityInSeconds = (refreshToken?.exp ?? 0) - (refreshToken?.iat ?? 0);
      if (refreshTokenValidityInSeconds < 0 || refreshTokenValidityInSeconds === 0) {
        this.idleTime = (config.idleTimeout ?? 300) * 1000;
      } else {
        console.log(`refreshTokenValidityInSeconds: ${refreshTokenValidityInSeconds}`);
        this.idleTime = (config.idleTimeout ?? refreshTokenValidityInSeconds) * 1000;
      }
    });
    if (keycloakService.isLoggedIn()) {
      this.resetTimer();
      this.setupEvents();
    }
  }

  private setupEvents() {
    const events = ['load', 'mousemove', 'mousedown', 'touchstart', 'click', 'keypress', 'scroll'];
    events.forEach((event) => window.addEventListener(event, () => this.resetTimer()));
  }

  private resetTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => this.logout(), this.idleTime);
    });
  }

  private logout() {
    this.ngZone.run(() => {
      this.keycloakService.idleTimeLogout();
    });
  }
}
