import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetCopResponseStateMatchStatusCode,
  SetCopResponseStateName,
  SetCopResponseStateReason,
} from '../actions/cop-response.action';

export interface CopResponseStateModel {
  name: string;
  matchStatusCode: string;
  reason: string;
}
@State<CopResponseStateModel>({
  name: 'CopResponseState',
  defaults: {
    name: '',
    matchStatusCode: '',
    reason: '',
  },
})
@Injectable()
export class CopResponseState {
  @Selector()
  static getCopResponseState(state: CopResponseStateModel) {
    return state;
  }

  @Action(SetCopResponseStateName)
  setCopResponseStateName(ctx: StateContext<CopResponseStateModel>, action: SetCopResponseStateName) {
    ctx.setState({
      ...ctx.getState(),
      name: action.payload,
    });
  }

  @Action(SetCopResponseStateReason)
  setCopResponseStateReason(ctx: StateContext<CopResponseStateModel>, action: SetCopResponseStateReason) {
    ctx.setState({
      ...ctx.getState(),
      reason: action.payload,
    });
  }
  @Action(SetCopResponseStateMatchStatusCode)
  setCopResponseStateMatchStatusCode(
    ctx: StateContext<CopResponseStateModel>,
    action: SetCopResponseStateMatchStatusCode,
  ) {
    ctx.setState({
      ...ctx.getState(),
      matchStatusCode: action.payload,
    });
  }
}
