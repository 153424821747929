// Define a unified type for both flags and context
export type FeatureFlagsAndContext = {
  [key: string]: {
    enabled?: boolean; // Optional: For feature flags
    value: boolean | string; // Used for both flags and context (can be boolean or string)
  };
};

// Single constant for both flags and context
export const DEFAULT_FLAGS_AND_CONTEXT: FeatureFlagsAndContext = {
  'bulk-payment-cop': {
    enabled: false, // Used by Flagsmith or Unleash as feature flag status
    value: 'false', // Used as a context property or flag value
  },
  'phone-otp-api-calls': {
    enabled: false, // Used by Flagsmith or Unleash as feature flag status
    value: 'false', // Used as a context property or flag value
  },
};
