<div class="h-100" *ngIf="virtualCardData && cardConfigData && isCardAvailable; else noCardAvailable">
  <div
    class="card isRelative h-100"
    [ngClass]="{
      'card-frozen': isFreezeCard,
      'card-flipped': isCardFlipped,
      'card-height-transfer': pageType === 'transfer',
      'card-height': pageType === 'transfer'
    }"
  >
    <div class="card-body" [ngStyle]="borderStyle">
      <div
        class="card-front pointer-events-none"
        (click)="goToCardManagement()"
        [ngClass]="{
          'card-frozen': isFreezeCard
        }"
      >
        <div class="row" style="height: 30%; justify-content: space-between; align-items: center">
          <div class="ml-4 col-6 is-left">
            <span *ngIf="parent.leftTopPlaceholderText.length > 0" class="typog-xs">
              {{ parent.leftTopPlaceholderText }}
            </span>
          </div>
          <div class="is-right w-100">
            <span *ngIf="cardConfigData['showCardDetails']" class="masked-pan font-weight-medium">
              {{ formatMaskedPan(virtualCardData.maskedPan) }}
            </span>

            <!-- Delete virtual card -->
            <finxone-web-frontend-virtual-card-options
              [virtualCardData]="virtualCardData"
              [isVirtualCard]="isVirtualCard"
              [paymentProvider]="parent.paymentGatewayConfig.payment"
            >
            </finxone-web-frontend-virtual-card-options>
          </div>
        </div>
        <div class="row mb-4 align-card-bottom mt-auto ff-unset">
          <div class="ml-4 col-6 text-left">
            <span class="card-format"> {{ virtualCardData.type | titlecase }} Card </span>
          </div>
          <div class="mr-4 text-right di-rtl">
            <span class="account-name" *ngIf="parent.showCardHolder">
              <b>{{ virtualCardData.name }} </b><span></span
            ></span>
            <p *ngIf="cardConfigData['showCardDetails'] && parent.showExpiry" class="card-logo">
              <span class="">{{ getExpiryDateWithFormat(virtualCardData.expiry) }}</span>
            </p>
          </div>
        </div>
        <div *ngIf="isCardActivation">
          <finxone-web-frontend-card-activation
            [borderStyleCardActivationStripe]="borderStyleCardActivationStripe"
            [parentAttribute]="attri"
          ></finxone-web-frontend-card-activation>
        </div>
        <finxone-web-frontend-frozen-strip *ngIf="isFreezeCard"></finxone-web-frontend-frozen-strip>
      </div>

      <div class="card-back" [ngStyle]="borderStyle">
        <div class="blank-area" [ngStyle]="borderStyleBlackStripe"></div>
        <div class="back-content">
          <div class="row">
            <div class="col-12 eye-icon-wrapper mb-0">
              <span
                [ngStyle]="{
                  visibility: cardConfigData['showCardBackSideDetailsIcon'] ? 'visible' : 'hidden'
                }"
                (click)="showCardBackSideDetails()"
              >
                <mat-icon
                  class="eye-icon"
                  [svgIcon]="isShowCardDetails ? 'eye-white' : 'eye-slash-white'"
                ></mat-icon>
              </span>
            </div>
          </div>
          <div class="card-strip">
            <img src="/assets/images/card-back-strip.svg" alt="eye" class="img-fluid" />
          </div>
          <div class="row card-details">
            <div class="col-3 mb-0">
              <p>CVV</p>
              <span class="card-info"> {{ secureCVVDetails.cvv2 ? secureCVVDetails.cvv2 : '***' }} </span
              >&nbsp;
              <span
                *ngIf="isShowCardDetails"
                class="copy-action"
                (click)="copyToClipBoard(secureCVVDetails.cvv2)"
                >(Copy)</span
              >
            </div>

            <div class="col-9 mb-0">
              <p>Card Number</p>
              <span class="card-info">
                {{ secureCVVDetails.pan ? secureCVVDetails.pan : virtualCardData.maskedPan }}
              </span>
              &nbsp;
              <span
                *ngIf="isShowCardDetails"
                class="copy-action"
                (click)="copyToClipBoard(secureCVVDetails.pan)"
                >(Copy)</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noCardAvailable>
  <div class="card isRelative no-card h-100">
    <div class="card-body">
      <div class="card-front" [ngStyle]="borderStyle">
        <div class="row" style="justify-content: space-between" (click)="openAddCardBottomPopup()">
          <mat-icon svgIcon="credit-card"></mat-icon>
          <div class="title">Add a new Card</div>
          <p class="subtitle">No Cards are registered to this account</p>
          <div class="line"></div>
          <div class="add-card-sub-title">+ Add Card</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
