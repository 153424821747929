import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AutoDestroy } from '@finxone-platform/shared/base-types';

@Injectable({
  providedIn: 'root',
})
export class BackOverrideService {
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();
  public backOverride?: { route: string; start: string; action: string };

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart && this.hasBackOverride()),
        takeUntil(this.destroy$),
      )
      .subscribe((start: Event) => {
        const navStart = start as NavigationStart;
        this.handleBackOverride(navStart);
      });
  }

  private handleBackOverride(navStart: NavigationStart) {
    // if user moved away from starting place we want to override, then clear backOverride
    if (this.router.url !== this.backOverride?.start) this.clearBackOverride();
    // otherwise specifically detect a back nav event, override it and clear backOverride and window history
    else if (navStart.navigationTrigger === 'popstate' && navStart.restoredState) {
      if (navStart.restoredState.navigationId < navStart.id) {
        const safeRoute = this.getBackOverrideRoute();
        if (safeRoute) {
          this.router.navigateByUrl(safeRoute, { replaceUrl: true, skipLocationChange: true }).then(() => {
            this.clearBackOverride();
            window.history.pushState(null, '', safeRoute);
          });
        }
      }
    }
  }

  setBackOverride(route: string, start: string, action: string): void {
    this.backOverride = { route, start, action };
  }

  hasBackOverride(): boolean {
    return !!this.backOverride;
  }

  getBackOverrideRoute(): string | undefined {
    return this.backOverride?.route;
  }

  clearBackOverride(): void {
    this.backOverride = undefined;
  }
}
