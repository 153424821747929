import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { Locale } from '@finxone-platform/shared/enums';
import { SupportedDevice, ThemeConfig, registerCustomIcons } from '@finxone-platform/shared/sys-config-types';
import { Select, Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { KeycloakService } from 'keycloak-angular';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ConfigService } from './lib/services/config-service/config-service.service';
import { CountriesCurrenciesService } from './lib/services/countries-currencies-service/countries-currencies.service';
import { DeviceTypeService } from './lib/services/device-type/device-type.service';
import { IdleTimeoutService } from './lib/services/idle-timeout-service/idle-timeout.service';
import { ProgressBarState } from './lib/state/progress-bar.state';
import { ProjectSettingsState } from './lib/state/project-settings.state';
import { isAuthenticatedRoute } from './lib/utils/auth-checks.utils';
import { logLoadEventToFlutter } from './lib/utils/flutter.utils';
import { registerHandlerbarHelpers } from './lib/utils/template/handlebar-helpers/handlerbar-helper-register.utils';
import { setupNewThemeing } from './lib/utils/themeing.utils';
import { clearLocalStorageExcept } from './lib/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  title = 'finxone-web-frontend';
  secondaryFontColour = '';
  fontFamily = '';
  fontFamilyUrl = '';
  thirdColour = '';
  secondaryFontFamily = '';
  deviceInfo: DeviceInfo;

  @Select(ProgressBarState.getProgressBarStack)
  progressBarStack: Observable<{ uniqueId?: string | number }[]>;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private deviceDetectorService: DeviceDetectorService,
    private deviceTypeService: DeviceTypeService,
    protected keycloakService: KeycloakService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private idleTimeoutService: IdleTimeoutService,
    private countriesCurrenciesService: CountriesCurrenciesService,
    private store: Store,
  ) {}
  ngOnInit() {
    this.onLoadBfCache();
    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Initing main app component',
      level: 'info',
    });
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.deviceTypeService.deviceType = this.deviceInfo.deviceType as SupportedDevice;

    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Registering handlebar helpers',
      level: 'info',
    });
    registerHandlerbarHelpers(this.datePipe, this.currencyPipe, this.countriesCurrenciesService);
    this.configService
      .getTheme()
      .pipe(
        takeUntil(this.destroy$),
        map((theme: ThemeConfig) => {
          Sentry.addBreadcrumb({
            category: 'Init',
            message: 'Setting up theming',
            level: 'info',
          });
          setupNewThemeing(theme);
        }),
      )
      .subscribe();

    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Registering custom icons',
      level: 'info',
    });
    registerCustomIcons(this.matIconRegistry, this.sanitizer);
    const locale = this.store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.locale ?? Locale.en_GB;
    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Refreshing alerts based on locale',
      level: 'info',
    });
    this.configService.refreshAlerts(locale as Locale);

    document.addEventListener('touchstart', function () {}, true); // allow :active styles to work in Mobile Safari
    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Loggin angular started to main mobile app',
      level: 'info',
    });
    logLoadEventToFlutter('angular-started');
  }

  private onLoadBfCache() {
    this.configService
      .getFeatureFlags()
      .pipe(
        takeUntil(this.destroy$),
        map((response) => {
          if (!response?.bfCacheAlertDetection) {
            return;
          }

          window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
              console.log('This page was restored from the bfcache.');
              const token = localStorage.getItem('token');
              /**
               * @description: checking if token is not there and user tries to login authenticated path then it wont go inside and it will redirect to login screen  .
               */
              if (!token && isAuthenticatedRoute(window.location.pathname)) {
                clearLocalStorageExcept([
                  'account',
                  'transactions',
                  'beneficiary',
                  'notifications',
                  'lastLoggedInId',
                  'APP_PREFERENCES',
                  'firstTime',
                ]);
                this.keycloakService.login({
                  redirectUri: window.location.href,
                });
              }
            } else {
              console.log('This page was loaded normally.');
            }
          });
        }),
      )
      .subscribe();
  }
}
