import { Router } from '@angular/router';
import { SetImageAction } from '@app/finxone-web-frontend/app/lib/actions/image-submission.action';
import { KeycloakWrapperService } from '@app/finxone-web-frontend/app/lib/services/keycloak-wrapper-service/keycloak-wrapper.service';
import { RoleStateModel } from '@app/finxone-web-frontend/app/lib/state/role.state';
import { setActiveRoleIfNeeded } from '@app/finxone-web-frontend/app/lib/utils/quick-link-page-url.utils';
import { checkRoleEquality } from '@app/finxone-web-frontend/app/lib/utils/utils';
import {
  ProviderClass,
  UiZoneWidgetAttributeConfig,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';

export abstract class BaseComponent {
  static showMargin = true; //property to set for hide/show margin
  static showPadding = true; //property to set for hide/show padding
  static showTextColor = false; //property to set for hide/show color picker
  static showTextAlignment = false; //property to set for hide/show text alignment
  static showBorderRadius = false; //property to set for hide/show border radius
  static showBorderWidth = false; //property to set for hide/show border width
  static showBorderColor = false; //property to set for hide/show border color
  static showBackGroundColor = false; //property to set for hide/show border color
  static showVerticalAlignment = false; //property to set for hide/show vertical align
  static showDisabledTextColor = false; //property to set for hide/show disabled color
  static showDisabledBgColor = false; //property to set for hide/show disabled bg color
  static showTextInput = false; //property to set for hide/show disabled text input
  static showTextBold = false; //property to set for hide/show disabled text input
  static showTextItalic = false; //property to set for hide/show disabled text input
  static showTextUnderline = false; //property to set for hide/show disabled text input
  static showImageCategoryOption = false; //property to set for hide/show disabled bg color
  contentStyle: baseCssConfigStyle; //property to set css in main div
  attri: UiZoneWidgetAttributeConfig;
  parentNavigationData?: {
    url: string;
    role: string;
    zone: string;
  };
  parentRoleInfo?: RoleStateModel;
  parentRouter: Router;
  navigationOption = '';
  parentStore: Store;
  parentKeycloakService: KeycloakWrapperService;
  //add new property for the base component here,
  public goToNavigationLink() {
    const externalLink = this.attri['externalUrl'] ?? null;
    const browserBehaviour = this.attri['browserBehaviour'] ?? null;

    if (browserBehaviour && externalLink) {
      if (browserBehaviour === 'new-tab') {
        window.open(externalLink, '_blank');
      } else if (browserBehaviour === 'new-window') {
        window.open(externalLink, '_blank', 'width=800, height=600');
      } else {
        window.open(externalLink, '_self');
      }
      return;
    }
    if (this.navigationOption && this.navigationOption == 'use-as-next-btn') {
      this.parentStore.dispatch(new SetImageAction({ useAsNext: true }));
      return;
    } else {
      this.parentStore.dispatch(new SetImageAction({ useAsNext: false }));
    }
    if (this.parentNavigationData?.role && this.parentRoleInfo) {
      const { isSameRole, isOnboardingOrVerification } = checkRoleEquality(
        this.parentRoleInfo,
        this.parentNavigationData,
      );
      if (
        (isSameRole && this.parentNavigationData.url) ||
        isOnboardingOrVerification ||
        this.parentNavigationData.role === 'no-role'
      ) {
        setActiveRoleIfNeeded(this.parentNavigationData.zone, this.parentStore);

        // if login redirect then send to keycloak directly
        this.parentRouter.navigate([this.parentNavigationData.url]);
        if (this.parentNavigationData.url?.includes('/zones/login')) {
          this.parentKeycloakService.login({
            redirectUri: window.location.origin + '/post-auth',
          });
        } else {
          this.parentRouter.navigate([this.parentNavigationData.url]);
        }
      }
    }
  }

  public getNestedWidgetConfig(dataIn: ProviderClass<any>, uniqueId: string): UiZoneWidgetAttributeConfig {
    const nestedWidgets = dataIn.widgetConfig.nestedWidgets;

    if (nestedWidgets) {
      const config = nestedWidgets[uniqueId];
      if (config) return config;
    }
    throw new Error(
      `No nested widgets present in config while searching for: ${uniqueId} in ${dataIn.widgetConfig.name}`,
    );
  }
}
